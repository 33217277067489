<template>
  <div id="edit-epg-content">
    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon
            icon="FileTextIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('epg.dataEpgContent') }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :show="showContent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t('epg.dataEpgContent') }}</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-form @submit.prevent="updateInfoEPGContent">
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('firstName')">
                      <b-form-input
                        id="name"
                        v-model="nameContent"
                        type="text"
                        maxlength="150"
                        :placeholder="$t('firstName')"
                        autocomplete="new-password"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('epg.urlImg')">
                      <b-form-input
                        id="name"
                        v-model="imageContent"
                        type="text"
                        maxlength="1000"
                        :placeholder="$t('epg.urlImg')"
                        autocomplete="new-password"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group :label="$t('dataGeneric.description')">
                      <b-form-textarea
                        id="description"
                        v-model="descriptionContent"
                        rows="3"
                        :placeholder="$t('dataGeneric.description')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col class="embed-responsive embed-responsive-16by9">
                    <b-img
                      class="mb-3 embed-responsive-item"
                      style="max-width:100%; height:50%;"
                      :src="
                        imageContent == null
                          ? require('@/assets/images/backend/placeholder.png')
                          : imageContent
                      "
                      fluid
                      block
                      rounded
                      @click="focus"
                    />
                  </b-col>
                </b-row>
                <b-row
                  style="text-align: left;"
                  class="m-1"
                >
                  <b-col>
                    <b-button
                      type="submit"
                      variant="success"
                    >
                      {{
                        $t("dataGeneric.save")
                      }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="ClockIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('epg.timeDuration') }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :show="showTime"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t('epg.timeDuration') }}</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-form @submit.prevent="updateTimeEPGContent">
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('epg.startDate')">
                      <flat-pickr
                        v-model="timeContent"
                        class="form-control"
                        :placeholder="$t('epg.startDate')"
                        :config="{
                          enableTime: true,
                          enableSeconds: true,
                          dateFormat: 'Y-m-d H:i:S'
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('contents.Duration')">
                      <b-form-input
                        id="duration"
                        v-model="durationContent"
                        type="number"
                        :min="0"
                        :placeholder="$t('epg.contentDuration')"
                        autocomplete="new-password"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row
                  style="text-align: left;"
                  class="m-1"
                >
                  <b-col>
                    <b-button
                      type="submit"
                      variant="success"
                    >
                      {{
                        $t("dataGeneric.save")
                      }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="FilePlusIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('epg.dataLinked') }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :show="showData"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t('epg.dataLinked') }}</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-form @submit.prevent="updateDataEPGContent">
                <b-row>
                  <b-col>
                    <b-form-group
                      :label="$t('epg.ageClasific')"
                      class="mb-2 mr-1"
                    >
                      <b-form-select
                        id="clasification"
                        v-model="clasificationContent"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      >
                        <b-form-select-option :value="null">
                          {{ $t('epg.ageClasific') }}
                        </b-form-select-option>
                        <b-form-select-option
                          v-for="s in allClasification"
                          :key="s.node.id"
                          :value="s.node.id"
                        >
                          <strong :style="'color:' + s.node.color + ';'">
                            {{ s.node.initial }} - {{ s.node.description }}
                          </strong>
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label="$t('epg.genre')"
                      class="mb-2 mr-1"
                    >
                      <b-form-select
                        id="genre"
                        v-model="genreContent"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="allGenre"
                        label="text"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="9">
                    <div @click="$refs['my-modal'].show()">
                      <b-form-input
                        id="contenido"
                        v-model="vinculoContentName"
                        disabled
                        type="text"
                        :placeholder="$t('epg.linkedContent')"
                        autocomplete="new-password"
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <b-button
                      variant="info"
                      @click="$refs['my-modal'].show()"
                    >
                      {{ $t('select') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row
                  style="text-align: left;"
                  class="m-1"
                >
                  <b-col>
                    <b-button
                      type="submit"
                      variant="success"
                    >
                      {{
                        $t("dataGeneric.save")
                      }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
    </b-tabs>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('contents.selectContent')"
    >
      <div class="d-block text-center">
        <content-selector
          @data="SelectContentId"
          @url="SelectContentUrl"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['my-modal'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
  </div>
</template>
<script>

import {
  BTab,
  BTabs,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BFormSelectOption,
  BButton,
  BFormGroup,
  BCard,
  BImg,
  BCardHeader,
  BCardBody,
  BOverlay,
  BCardTitle,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, utf8ToB64, showToast } from '@/store/functions'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import { getUserData } from '@/auth/utils'
import ContentSelector from '@/views/common/ContentSelector.vue'

export default {
  components: {
    BTab,
    BTabs,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BFormSelectOption,
    BButton,
    BFormGroup,
    BCard,
    BImg,
    flatPickr,
    BCardHeader,
    BCardBody,
    ContentSelector,
    BOverlay,
    BCardTitle,
  },
  data() {
    return {
      allGenre: [],
      allClasification: [],
      nameContent: null,
      imageContent: null,
      descriptionContent: null,
      timeContent: null,
      durationContent: null,
      clasificationContent: null,
      genreContent: null,
      vinculoContentName: null,
      vinculoContentId: null,
      showData: false,
      showContent: false,
      showTime: false,
      userData: getUserData(),
      idEpg: null,
    }
  },
  mounted() {
    this.idEpg = this.$route.params.epg
    this.getData()

    this.clasification()
    this.genre()
  },
  methods: {
    focus() {
      document.getElementById('image').focus()
    },
    updateDataEPGContent() {
      this.showContent = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }

      const query = `
          mutation {
           updateEpgContent(id:"${this.idEpg}",input:{
             ${this.clasificationContent != null
    ? `clasification:"${utf8ToB64(
      `id:${this.clasificationContent}`,
    )}",`
    : ''
}
             ${this.genreContent != null
    ? `genre:"${utf8ToB64(`id:${this.genreContent}`)}",`
    : ''
}
             ${this.vinculoContentId != null
    ? `content:"${utf8ToB64(
      `id:${this.vinculoContentId}`,
    )}",`
    : ''
}

          
          
        
         }){
            epgContent{
              name
            }
          }
        }
        `

      data.append('query', query)
      axios
        .post('', data, config)
        .then(result => {
          messageError(result, this)

          showToast(this.$t('success'), 1, this)
          this.showContent = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    updateInfoEPGContent() {
      this.showData = true

      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }

      const query = `
          mutation ($description: String){
           updateEpgContent(id:"${this.idEpg}",input:{name:"${this.nameContent}",
            description: $description,

          image:"${this.imageContent}"}){
            epgContent{
              name
            }
          }
        }
        `
      const variables = {
        description: this.descriptionContent,
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      axios
        .post('', data, config)
        .then(result => {
          messageError(result, this)

          showToast(this.$t('success'), 1, this)
          this.showData = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    updateTimeEPGContent() {
      this.showTime = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }

      const query = `
          mutation ($startDate: DateTime){
           updateEpgContent(id:"${this.idEpg}",input:{
          startDate: $startDate,
          duration:${this.durationContent}}){
            epgContent{
              name
            }
          }
        }
        `
      const variables = {
        startDate: this.fechaFormato(this.timeContent),
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      axios
        .post('', data, config)
        .then(result => {
          messageError(result, this)

          showToast(this.$t('success'), 1, this)
          this.showTime = false
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    editarContenido(data) {
      this.nameContent = data.name
      this.imageContent = data.image
      this.descriptionContent = data.description
      this.timeContent = data.startDate
      this.durationContent = data.duration
      this.clasificationContent = data?.clasification?.id
      this.genreContent = data?.genre?.id
      this.vinculoContentName = data.content.name
      this.vinculoContentId = data.content.id
    },
    clasification() {
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
          query{
            allClasification {
              edges {
                node {
                 id
                type
                initial
                description
                color
                clasificationsClient {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allClasification.edges.forEach(element => {
            if (element.node.clasificationsClient.edges[0] != null) {
              element.node.clasificationsClient.edges.forEach(a => {
                if (a.node.id === id) {
                  this.allClasification.push(element)
                }
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    genre() {
      axios
        .post('', {
          query: `
            query{
            allGenre(client:"${this.userData.profile.client.id}") {
              edges {
                node {
                  id
                  name
                  description
                  isActive

                }
              }
            }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allGenre.edges.forEach(element => {
            if (element.node.isActive) {
              this.allGenre.push({
                value: element.node.id,
                text: element.node.name,
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getData() {
      axios
        .post('', {
          query: `
            {
        allEpgContent(id:"${this.idEpg}") {
            edges {
            node {
                 id
              startDate
              name
              image
              description
              duration
              clasification {
                id
                initial
              }
              genre {
                id
                name
              }
              content {
                id
                name
                imageUrl
              }
            }
            }
        }
        }
        `,
        })
        .then(result => {
          messageError(result, this)

          const res = result.data.data.allEpgContent.edges[0].node
          this.timeContent = this.fechaFormato(res.startDate)
          this.durationContent = res.duration
          this.descriptionContent = res.description
          this.imageContent = res.image
          this.nameContent = res.name

          this.$emit('return', { name: this.nameContent, viewName: this.$t('epg.conf'), viewDetail: `${this.$t('editContent.subtitle')} EPG` })

          try {
            this.genreContent = res.genre.id
          } catch (error) {
            console.log(error)
          }
          try {
            this.vinculoContentName = res.content.name
            this.vinculoContentId = res.content.id
          } catch (error) {
            console.log(error)
          }
          try {
            this.clasificationContent = res.clasification.id
          } catch (error) {
            console.log(error)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    goToBackPage() {
      window.history.back()
    },

    SelectContentId(data) {
      this.vinculoContentId = data
      this.$refs['my-modal'].hide()
    },
    SelectContentUrl(data) {
      this.vinculoContentName = data
      this.$refs['my-modal'].hide()
    },
    fechaFormato(value) {
      const fecha = new Date(value)

      return value != null ? fecha : value
    },
  },
}
</script>

<style lang="scss" >
  @import "@core/scss/vue/libs/vue-flatpicker.scss";

</style>
